import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="calspak-div">
            <h2>Privacy Policy</h2>

            <div className="privacy-section">
                <h3>1. Introduction</h3>
                <p>
                    At Calspak, accessible from{" "}
                    <a href="www.calspak.com">www.calspak.com</a>, one of our
                    main priorities is the privacy of our visitors. This Privacy
                    Policy document contains types of information that are
                    collected and recorded by Calspak and how we use it.
                </p>
                <p>
                    If you have additional questions or require more information
                    about our Privacy Policy, do not hesitate to contact us.
                </p>
            </div>

            <div className="privacy-section">
                <h3>2. Log Files</h3>
                <p>
                    Calspak follows a standard procedure of using log files.
                    These files log visitors when they visit websites. All
                    hosting companies do this and are a part of hosting
                    services' analytics. The information collected by log files
                    includes internet protocol (IP) address, browser type,
                    Internet Service Provider (ISP), date and time stamp,
                    referring/exit pages, and possibly the number of clicks.
                    This information is used to analyze trends, administer the
                    site, track user movement around the site, and gather
                    demographic information.
                </p>
            </div>

            <div className="privacy-section">
                <h3>3. Cookies and Web Beacons</h3>
                <p>
                    Like any other website, Calspak uses 'cookies'. These
                    cookies are used to store information including visitor
                    preferences, and the pages on the website that the visitor
                    accessed or visited. This information is used to optimize
                    the user experience by customizing our web page content
                    based on visitors' browser type and/or other information.
                </p>
                <p>
                    For more general information on cookies, please read "What
                    Are Cookies" from Cookie Consent.
                </p>
            </div>

            <div className="privacy-section">
                <h3>4. Third-Party Privacy Policies</h3>
                <p>
                    Calspak's Privacy Policy does not apply to other advertisers
                    or websites. We encourage you to consult the respective
                    privacy policies of these third-party ad servers for more
                    detailed information. Their practices may differ from ours
                    and are not covered by this Privacy Policy.
                </p>
            </div>

            <div className="privacy-section">
                <h3>5. Children's Information</h3>
                <p>
                    Another part of our priority is protecting the privacy of
                    children. We encourage parents and guardians to be
                    observant, participate in, and/or monitor their online
                    activity. Calspak does not knowingly collect any Personal
                    Identifiable Information (PII) from children under the age
                    of 13. If you think your child provided us with PII, please
                    contact us immediately and we will ensure the information is
                    promptly removed from our records.
                </p>
            </div>

            <div className="privacy-section">
                <h3>6. Changes to This Privacy Policy</h3>
                <p>
                    We may update our Privacy Policy from time to time. We will
                    notify you of any changes by posting the new Privacy Policy
                    on this page. These changes are effective immediately after
                    they are posted on this page.
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
