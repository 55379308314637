import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
        <nav className="navbar">
            <ul className="nav-list">
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/about">About Us</Link>
                </li>
                <li>
                    <Link to="/contact">Contact Us</Link>
                </li>
                {/* <li>
                    <Link to="/services">Services</Link>
                </li>
                <li>
                    <Link to="/research">Research</Link>
                </li>
                <li>
                    <Link to="/blog">Blog</Link>
                </li> */}
            </ul>
        </nav>
    );
};

export default Navbar;
