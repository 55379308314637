import React from "react";

import "./contact.scss";

const Careers = () => {
    return (
        <div>
            <div className="calspak-div">
                <div className="calspak-content">
                    <h2>Careers</h2>
                    <p>We currently have no open positions.</p>
                    <p>
                        We welcome spontaneous applications and ideas to develop
                        new collaborations. In case of interest, please do not
                        hesitate to get in touch with us.
                    </p>
                    <p>
                        Join our team at Calspak and be a part of a dynamic and
                        innovative company.
                    </p>
                </div>
            </div>
            <section className="calspak-section-dark">
                <div className="calspak-content">
                    <h2>How can we help you?</h2>
                    <p>
                        Interested in joining Calspak or need more information?
                        Contact us today to discuss how we can assist you. We
                        look forward to hearing from you!
                    </p>
                    <a href="/contact-us" className="calspak-btn">
                        Contact Us
                    </a>
                </div>
            </section>
        </div>
    );
};

export default Careers;
