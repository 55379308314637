import React from "react";

import logo from "../../src/assets/logo/logo_white.png";
import tagline from "../../src/assets/logo/tagline_white.png";

import "./header.scss";

const Header = () => {
    return (
        <header className="header">
            <div className="text-content">
                <img src={logo} alt="Calspak Logo" className="logo" />

                <img src={tagline} alt="Calspak Tagline" className="tagline" />
            </div>
        </header>
    );
};

export default Header;
