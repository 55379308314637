import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Main from "./components/Main";
import About from "./components/About";
// import Services from "./components/Services";
// import Research from "./components/Research";
// import Blog from "./components/Blog";
import Contact from "./components/Contact";
import Careers from "./components/Careers";
import FAQ from "./components/FAQ";
import UserTerms from "./components/UserTerms";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ScrollManager from "./components/ScrollManager";

import "./styles/styles.scss";
import "./styles/fonts.scss";

function App() {
    return (
        <Router>
            <ScrollManager />
            <Navbar />
            <Header />
            <Routes>
                <Route path="/" element={<Main />} />
                <Route path="/about" element={<About />} />
                {/* <Route path="/services" element={<Services />} /> */}
                {/* <Route path="/research" element={<Research />} /> */}
                {/* <Route path="/blog" element={<Blog />} /> */}
                <Route path="/contact" element={<Contact />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/user-terms" element={<UserTerms />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
