import React from "react";
import { NavLink } from "react-router-dom";

const UserTerms = () => {
    return (
        <div className="calspak-div">
            <h2>User Terms</h2>

            <div className="user-terms-section">
                <h3>1. Introduction</h3>
                <p>
                    Welcome to Calspak! These terms and conditions outline the
                    rules and regulations for the use of Calspak's website,
                    located at www.calspak.com. By accessing this website, we
                    assume you accept these terms and conditions. Do not
                    continue to use Calspak if you do not agree to take all of
                    the terms and conditions stated on this page.
                </p>
            </div>

            <div className="user-terms-section">
                <h3>2. Cookies</h3>
                <p>
                    We employ the use of cookies. By accessing Calspak, you
                    agreed to use cookies in agreement with the Calspak's
                    Privacy Policy. Most interactive websites use cookies to let
                    us retrieve the user’s details for each visit. Cookies are
                    used by our website to enable the functionality of certain
                    areas to make it easier for people visiting our website.
                </p>
            </div>

            <div className="user-terms-section">
                <h3>3. License</h3>
                <p>
                    Unless otherwise stated, Calspak and/or its licensors own
                    the intellectual property rights for all material on
                    Calspak. All intellectual property rights are reserved. You
                    may access this from Calspak for your own personal use
                    subjected to restrictions set in these terms and conditions.
                </p>
                <p>You must not:</p>
                <ul>
                    <li>Republish material from Calspak</li>
                    <li>Sell, rent, or sub-license material from Calspak</li>
                    <li>Reproduce, duplicate, or copy material from Calspak</li>
                    <li>Redistribute content from Calspak</li>
                </ul>
            </div>

            <div className="user-terms-section">
                <h3>4. Your Privacy</h3>
                <p>
                    Please read our{" "}
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>.
                </p>
            </div>

            <div className="user-terms-section">
                <h3>5. Reservation of Rights</h3>
                <p>
                    We reserve the right to request that you remove all links or
                    any particular link to our website. You approve to
                    immediately remove all links to our website upon request. We
                    also reserve the right to amend these terms and conditions
                    and its linking policy at any time. By continuously linking
                    to our website, you agree to be bound to and follow these
                    linking terms and conditions.
                </p>
            </div>

            <div className="user-terms-section">
                <h3>6. Removal of Links from Our Website</h3>
                <p>
                    If you find any link on our website that is offensive for
                    any reason, you are free to contact and inform us any moment
                    at{" "}
                    <a href="mailto:support@calspak.com">support@calspak.com</a>
                    . We will consider requests to remove links but we are not
                    obligated to or so or to respond to you directly.
                </p>
                <p>
                    We do not ensure that the information on this website is
                    correct, we do not warrant its completeness or accuracy; nor
                    do we promise to ensure that the website remains available
                    or that the material on the website is kept up to date.
                </p>
            </div>
        </div>
    );
};

export default UserTerms;
