import React from "react";

const FAQ = () => {
    return (
        <div className="calspak-div">
            <h2>Frequently Asked Questions</h2>
            <div className="calspak-content">
                <h3>What services does Calspak offer?</h3>
                <p>
                    Calspak offers a wide range of innovative services,
                    including technology consulting, software development, and
                    research partnerships. Our team is dedicated to delivering
                    exceptional solutions tailored to your needs.
                </p>
            </div>
            <div className="calspak-content">
                <h3>How can I contact Calspak for support?</h3>
                <p>
                    You can reach our support team by emailing{" "}
                    <a href="mailto:support@calspak.com">support@calspak.com</a>
                    . Our support hours are Monday to Friday, 9:00 to 17:00
                    (CET).
                </p>
            </div>
            <div className="calspak-content">
                <h3>Where is Calspak located?</h3>
                <p>Calspak is headquartered in Zurich, Switzerland.</p>
            </div>
            <div className="calspak-content">
                <h3>How can I apply for a job at Calspak?</h3>
                <p>
                    Visit our Careers page to view current job openings and
                    submit your application. We are always looking for talented
                    individuals to join our team.
                </p>
            </div>
        </div>
    );
};

export default FAQ;
