import React from "react";
import { FaGlobe, FaLinkedin } from "react-icons/fa";

import adas from "../assets/team/arnesh.das.jpeg";
import esaikia from "../assets/team/eashan.saikia.jpg";
import ikoureas from "../assets/team/ioannis.koureas.jpg";
import jleu from "../assets/team/jonathan.leu.jpg";
import ldavico from "../assets/team/luigi.davico.jpeg";
import lwalpen from "../assets/team/lukas.walpen.jpeg";

import lreiter from "../assets/advisors/lex.reiter.jpg";
import rflatt from "../assets/advisors/robert.flatt.jpg";

import "./about.scss";

const teamMembers = [
    {
        name: "Dr. Arnesh Das",
        role: "CEO and co-founder",
        image: adas,
        education: "PhD ETH Zurich",
        description:
            "Business development, fundraising, R&D, and material development coordination.",
        linkedin: "https://www.linkedin.com/in/arnesh-das-559879a0/",
    },
    {
        name: "Dr. Ioannis Koureas",
        role: "COO and co-founder",
        image: ikoureas,
        education: "PhD ETH Zurich",
        description:
            "Administration, finance, HR, and software development coordination.",
        linkedin: "https://www.linkedin.com/in/ioannis-koureas",
    },
    {
        name: "Lukas Walpen",
        role: "Material Development Lead",
        image: lwalpen,
        education: "MSc Student at ETH Zurich",
        description: "Research on low-carbon fast setting binders.",
        linkedin: "https://www.linkedin.com/in/lukas-walpen-5b7723289/",
    },
    {
        name: "Dr. Luigi D'Avico",
        role: "Admixture Development Lead",
        image: ldavico,
        education: "PhD ETH Zurich",
        description: "Chemical admixture development for fast-setting binders.",
        linkedin: "https://www.linkedin.com/in/luigi-d-avico-ph-d-1a4629138/",
    },
    {
        name: "Dr. Eashan Saikia",
        role: "Business Development Coordinator",
        image: esaikia,
        education: "PhD ETH Zurich",
        description:
            "Market analysis, sales strategy, and pricing model development.",
        linkedin: "https://www.linkedin.com/in/esaikia/",
    },
    {
        name: "Jonathan Leu",
        role: "Lab Technician",
        image: jleu,
        education: "5+ years of industry experience",
        description: "Material testing and validation.",
    },
];

const advisors = [
    {
        name: "Prof. Dr. Robert J. Flatt",
        role: "Scientific Advisor",
        image: rflatt,
        education: "Professor of Building Materials at ETH Zurich",
        description:
            "Expertise on chemical admixtures for concrete and cement chemistry.",
        linkedin: "https://www.linkedin.com/in/robert-flatt-177b6a14/",
        website: "https://ifb.ethz.ch/pcbm/people/robert-flatt.html",
    },
    {
        name: "Dr. Lex Reiter",
        role: "Scientific Advisor",
        image: lreiter,
        education: "PhD ETH Zurich",
        description:
            "Expertise on set-on-demand concrete and rheology of concrete.",
    },
];

const About = () => {
    return (
        <div>
            <div className="calspak-div">
                <h2>About Us</h2>
                <div className="about-intro">
                    <p>
                        At Calspak, we are a team of motivated individuals with
                        diverse skills who are passionate about providing
                        cutting-edge services to our clients. We believe in
                        fostering a culture of collaboration and innovation in a
                        dynamic working environment.
                    </p>
                </div>

                <div className="about-mission">
                    <h3>Our Mission</h3>
                    <p>
                        In line with the UN Sustainable Development Goals #11
                        and #12, we aim to significantly reduce the carbon
                        footprint associated with the construction industry, as
                        well as to enhance production efficiency, by exploiting
                        a unique material formulation. The implementation of the
                        Calspak technology in practice will help to transform
                        the construction industry, drastically reduce carbon
                        footprint, and increase productivity.
                    </p>
                </div>

                {/* <div className="about-vision">
                    <h3>Our Vision</h3>
                    <p></p>
                </div> */}

                {/* <div className="about-values">
                    <h3>Our Values</h3>
                    <p>
                        
                    </p>
                </div> */}

                {/* <div className="about-team">
                    <h3>Meet Our Team</h3>
                    <p>
                        
                    </p>
                </div> */}

                {/* Team Section */}
                <div className="team">
                    <h3>Meet the people behind Calspak</h3>
                    <p>
                        Our diverse team of professionals brings a wealth of
                        knowledge and experience. We are enthusiastic about what
                        we do, and this enthusiasm motivates us to go above and
                        beyond expectations to provide our clients with
                        exceptional outcomes.
                    </p>
                    <div className="team-grid">
                        {teamMembers.map((member) => (
                            <div key={member.name} className="team-card">
                                <img
                                    src={member.image}
                                    alt={member.name}
                                    className="team-image"
                                />
                                <div className="team-info">
                                    <h4 className="team-name">{member.name}</h4>
                                    <p className="team-role">{member.role}</p>
                                    <div className="team-description">
                                        <p>{member.education}</p>
                                        <p>{member.description}</p>

                                        {member.linkedin && (
                                            <a
                                                href={member.linkedin}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="linkedin-icon"
                                            >
                                                <FaLinkedin />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Advisors Section */}
                <div className="advisors">
                    <h3>Our Advisors</h3>
                    <p>
                        Our advisors are scientific experts who bring invaluable
                        knowledge and guidance to our projects. Their expertise
                        helps us stay at the forefront of our industry.
                    </p>
                    <div className="advisors-grid">
                        {advisors.map((advisor) => (
                            <div key={advisor.name} className="advisor-card">
                                <img
                                    src={advisor.image}
                                    alt={advisor.name}
                                    className="advisor-image"
                                />
                                <div className="advisor-info">
                                    <h4 className="advisor-name">
                                        {advisor.name}
                                    </h4>
                                    <p className="advisor-role">
                                        {advisor.role}
                                    </p>
                                    <div className="advisor-description">
                                        <p>{advisor.education}</p>
                                        <p>{advisor.description}</p>

                                        {advisor.linkedin && (
                                            <a
                                                href={advisor.linkedin}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FaLinkedin className="linkedin-icon" />
                                            </a>
                                        )}
                                        {advisor.website && (
                                            <a
                                                href={advisor.website}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FaGlobe className="website-icon" />
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* <div className="about-history">
                    <h3>Our History</h3>
                    <p></p>
                </div> */}
            </div>

            <section className="calspak-section-dark">
                <div className="calspak-content">
                    <h2>How can we help you?</h2>
                    <p>
                        Ready to start a project or need more information?
                        Contact us today to discuss how we can assist you. We
                        look forward to partnering with you!
                    </p>
                    <a href="/contact-us" className="calspak-btn">
                        Contact Us
                    </a>
                </div>
            </section>
        </div>
    );
};

export default About;
