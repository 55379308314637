import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlay,
    faRocket,
    faDna,
    faUsers,
    faLightbulb,
    faCog,
    faChartLine,
    faDollarSign,
    faLeaf,
} from "@fortawesome/free-solid-svg-icons";

import model from "../assets/img/model.png";

import "./main.scss";

// Dynamic Service Component
const Service = ({ icon, title, description }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDescription = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="service-item">
            <FontAwesomeIcon icon={icon} className="service-icon" />
            <div className="service-content">
                <h2 onClick={toggleDescription} className="service-title">
                    {title}
                </h2>
                {isOpen && <p className="service-description">{description}</p>}
            </div>
        </div>
    );
};

const Main = () => {
    const keyPoints = [
        {
            icon: faLeaf,
            title: "Up to 60% Reduction of GHG Emissions",
        },

        {
            icon: faDollarSign,
            title: "Up to 20% Reduction of Production Costs",
        },
        {
            icon: faChartLine,
            title: "Up to 50% Increase in Productivity",
        },
    ];

    // const testimonials = [
    //     {
    //         imgSrc: "https://via.placeholder.com/100?text=Client+1",
    //         quote: "Calspak's innovative solutions transformed our operations and significantly improved our efficiency. Their team is professional and dedicated to delivering top-notch results.",
    //         author: "John Doe, CEO of AAA",
    //     },
    //     {
    //         imgSrc: "https://via.placeholder.com/100?text=Client+2",
    //         quote: "The expertise and support provided by Calspak were invaluable. Their research and development team helped us achieve our goals and stay ahead of the competition.",
    //         author: "Jane Doe, Director of AAA",
    //     },
    // ];

    return (
        <div>
            <section className="calspak-section-light">
                <div className="calspak-content">
                    <h2 className="main-title">Build QUICK - Build GREEN</h2>
                    <p>
                        <em>
                            Calspak is a clean-tech startup from ETH Zurich,
                            Switzerland, developing ultra-low-carbon cement with
                            one of the quickest setting times offered by any
                            alternatives in the industry.
                        </em>
                    </p>
                    <p>
                        <em>
                            The Calspak team is committed to significantly
                            reducing the GHG emissions associated with the
                            construction industry as well as to enhance
                            production efficiency by exploiting our patented
                            material formulation resulting from years of
                            fundamental research.
                        </em>
                    </p>
                </div>
            </section>

            <section className="calspak-section-light">
                <div className="service-content">
                    <div className="service-items">
                        <div className="items-content">
                            {/* Unmet industry need */}
                            <Service
                                title={
                                    <div className="title-with-icon">
                                        <FontAwesomeIcon
                                            icon={faRocket}
                                            className="service-icon"
                                        />
                                        <h2>Unmet Industry Need</h2>
                                    </div>
                                }
                                description={
                                    <>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            High carbon footprint associated
                                            with the concrete industry.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            Slow cement setting, leading to
                                            prolonged construction times and
                                            delays.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />{" "}
                                            Increased labour costs as a
                                            consequence of technical delays.
                                        </p>
                                    </>
                                }
                            />

                            {/* Key features of our technology */}
                            <Service
                                title={
                                    <div className="title-with-icon">
                                        <FontAwesomeIcon
                                            icon={faDna}
                                            className="service-icon"
                                        />
                                        <h2>Key features of our technology</h2>
                                    </div>
                                }
                                description={
                                    <>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />{" "}
                                            Extremely low cement content: 4-5
                                            times lower than conventional
                                            concrete.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            Reduction of GHG emissions by up to
                                            60% compared to conventional
                                            concrete of equivalent performance.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            Reduction of cost of production by
                                            up to 20% compared to conventional
                                            concrete of equivalent performance.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            Rapid strength development: Increase
                                            of productivity by 50%.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            Shorter delivery times of concrete
                                            products from 7 days to 3-4 days.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            Calspak technology can be applied to
                                            a wide range of concrete products.
                                        </p>
                                    </>
                                }
                            />

                            {/* Our customers */}
                            <Service
                                title={
                                    <div className="title-with-icon">
                                        <FontAwesomeIcon
                                            icon={faUsers}
                                            className="service-icon"
                                        />
                                        <h2>Our customers</h2>
                                    </div>
                                }
                                description={
                                    <>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            Precast as well as ready-mix
                                            concrete companies can use our
                                            Calspak binder instead of
                                            conventional cement to produce a
                                            wide range of concrete products.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            End-users of the concrete products
                                            benefit due to the lower cost as
                                            well as enhanced delivery times.
                                        </p>
                                    </>
                                }
                            />

                            {/* Research & Development */}
                            <Service
                                title={
                                    <div className="title-with-icon">
                                        <FontAwesomeIcon
                                            icon={faLightbulb}
                                            className="service-icon"
                                        />
                                        <h2>Research & Development</h2>
                                    </div>
                                }
                                description={
                                    <>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            At the forefront of technological
                                            advancements, we are dedicated to
                                            exploring new designs and creating
                                            innovative products for the
                                            manufacturing sector.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            Discover how our research can help
                                            your business evolve sustainably.
                                        </p>
                                    </>
                                }
                            />

                            {/* Technical Support */}
                            <Service
                                title={
                                    <div className="title-with-icon">
                                        <FontAwesomeIcon
                                            icon={faCog}
                                            className="service-icon"
                                        />
                                        <h2>Technical Support</h2>
                                    </div>
                                }
                                description={
                                    <>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            We offer service to our customers to
                                            customize the ultra-low-carbon and
                                            low-cost concrete mix designs, and
                                            help them achieve the market
                                            required properties.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            We provide comprehensive technical
                                            support throughout the production
                                            cycle ensuring everything runs
                                            smoothly.
                                        </p>
                                        <p>
                                            <FontAwesomeIcon
                                                icon={faPlay}
                                                className="service-icon"
                                            />
                                            Our team is available to assist with
                                            troubleshooting, maintenance, and
                                            any other technical issues you may
                                            encounter.
                                        </p>
                                    </>
                                }
                            />
                        </div>
                        <div className="image-content">
                            <img
                                className="model-image"
                                src={model}
                                alt="Description of model"
                            />
                            <p className="caption">
                                <b>Microstructure of Calspak concrete!</b>
                            </p>
                            <p className="caption">
                                <em>
                                    Our unique material formulation allows to
                                    produce concrete with extremely low amounts
                                    of cement with enhanced properties compared
                                    to conventional concrete.
                                </em>{" "}
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="key-points-section">
                <h2 className="main-title">What We Bring?</h2>
                <div className="key-points-grid">
                    {keyPoints.map((point, index) => (
                        <div key={index} className="key-point-item">
                            <FontAwesomeIcon
                                icon={point.icon}
                                className="key-point-icon"
                            />
                            <h3 className="key-point-title">{point.title}</h3>
                        </div>
                    ))}
                </div>
            </section>

            {/* <section className="main-testimonials">
                <h2>What Our Clients Say</h2>
                {testimonials.map((testimonial, index) => (
                    <div key={index} className="testimonial-item">
                        <img
                            src={testimonial.imgSrc}
                            alt={testimonial.author}
                            className="testimonial-img"
                        />
                        <div className="testimonial-content">
                            <p>"{testimonial.quote}"</p>
                            <p>
                                <strong>- {testimonial.author}</strong>
                            </p>
                        </div>
                    </div>
                ))}
            </section> */}

            <section className="calspak-section-dark">
                <div className="calspak-content">
                    <h2>How can we help you?</h2>
                    <p>
                        Ready to start a project or need more information?
                        Contact us today to discuss how we can assist you. We
                        look forward to partnering with you!
                    </p>
                    <a href="/contact" className="calspak-btn">
                        Contact Us
                    </a>
                </div>
            </section>
        </div>
    );
};

export default Main;
